
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import MyListMixin from '@/mixins/mylist-mixin';
import { Component } from 'vue-property-decorator';

@Component({
  mixins: [MyListMixin],
  components: {
    BaseToolbar
  }
})
export default class LessonSearchToolbar extends BaseToolbar {
  get localComponent(): any {
    return this.currentPageInstance || {};
  }

  get searchText() {
    return this.localComponent.search || '';
  }

  set searchText(value: string) {
    this.localComponent.search = value;
  }

  get typing() {
    return this.localComponent.typing;
  }

  set typing(value: boolean) {
    this.localComponent.typing = value;
  }

  get doSearchLesson() {
    return this.localComponent.doSearchLesson || this.emptyFunction;
  }

  get clearSearch() {
    return this.localComponent.clearSearch || this.emptyFunction;
  }
}
