var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-toolbar", {
    attrs: { searchable: "", search: _vm.searchText },
    on: {
      "update:search": function ($event) {
        _vm.searchText = $event
      },
      typing: function ($event) {
        _vm.typing = true
      },
    },
    scopedSlots: _vm._u([
      {
        key: "toolbar-left",
        fn: function () {
          return [
            _c(
              "v-col",
              { staticClass: "px-0", attrs: { md: "2", sm: "3" } },
              [
                _c("v-text-field", {
                  attrs: {
                    outlined: "",
                    dense: "",
                    clearable: "",
                    "single-line": "",
                    "hide-details": "",
                    "prepend-inner-icon": "fal fa-search",
                    label: _vm.$t("searchLabel") + "*",
                    "aria-required": "true",
                  },
                  on: {
                    input: function ($event) {
                      _vm.typing = true
                    },
                  },
                  model: {
                    value: _vm.searchText,
                    callback: function ($$v) {
                      _vm.searchText = $$v
                    },
                    expression: "searchText",
                  },
                }),
              ],
              1
            ),
            _c(
              "pb-btn",
              {
                staticClass: "ml-2",
                attrs: { outlined: "", color: "primary", height: "40" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.doSearchLesson(true)
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("searchLabel")))])]
            ),
            _c(
              "pb-btn",
              {
                staticClass: "ml-2",
                attrs: { outlined: "", color: "error", height: "40" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clearSearch.apply(null, arguments)
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("clearLabel")))])]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "toolbar-right",
        fn: function () {
          return undefined
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }