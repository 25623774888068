var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isEmbedded
    ? _c(
        "v-toolbar",
        [
          _c(
            "v-row",
            {
              staticClass: "text-center justify-space-between",
              attrs: { elevation: "1", outlined: "" }
            },
            [
              _c("pb-user-mode-selector"),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "pb-btn",
                    {
                      attrs: { icon: "", color: "primary" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.doSearchLesson(true)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-search")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "pb-btn",
                    {
                      attrs: { icon: "", color: "error" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.clearSearch.apply(null, arguments)
                        }
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("clearLabel")))])]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }