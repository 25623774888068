
import { Component } from 'vue-property-decorator';
import LessonSearchToolbar from './LessonSearchToolbar.vue';

@Component({
  components: {
    LessonSearchToolbar
  }
})
export default class LessonSearchBottomToolbar extends LessonSearchToolbar {
}
